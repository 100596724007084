<template>
    <div>
        <div v-if="!joined">
            <!-- Pre-stream component -->
            <PreStream @joinNow="joinNow"></PreStream>
        </div>
        <div v-else>
            <!-- Stream component -->
            <Stream></Stream>
        </div>
    </div>
</template>

<script>
import { enterPreStreamPage, leavePlayersPage } from "../utils/players";
import PreStream from "./PreStream.vue";
import Stream from "./Stream.vue";

export default {
    components: {
        PreStream,
        Stream
    },
    data() {
        return {
            joined: false
        };
    },
    created() {
        // Hide all unnessary elements
        enterPreStreamPage();
    },
    beforeUnmount() {
        leavePlayersPage();
    },
    methods: {
        joinNow() {
            this.joined = true;
        }
    }
};
</script>

<style lang="scss" scoped></style>
