<script setup>
import { ref, defineEmits, onMounted, onUnmounted } from "vue";

// Handle stream events
const emits = defineEmits(["joinNow"]);

const handleJoinNow = () => {
    emits("joinNow");
};

const userCam = ref(null);
const mediaStream = ref(null);

/* onMounted(() => {
    navigator.mediaDevices
        .getUserMedia({ video: true })
        .then((stream) => {
            mediaStream.value = stream;
            userCam.value.srcObject = stream;
        })
        .catch((error) => {
            // Handle the error
            console.log(error);
        });
}); */

onUnmounted(() => {
    if (mediaStream.value) {
        mediaStream.value.getTracks().forEach((track) => track.stop());
    }
});
</script>
<template>
    <main>
        <div class="meeting-container md:m-auto md:w-9 md:flex md:flex-row md:justify-content-center md:align-items-center md:h-screen sm:flex sm:flex-column">
            <!-- <div class="meeting-container md:flex justify-content-center align-items-center h-screen sm:flex sm:flex-column"> -->
            <!-- <div class="meeting-container"> -->
            <!-- <div class="video-container md:border-round-2xl sm:flex-1 md:flex-none">
                <video ref="userCam" autoplay></video>
            </div> -->
            <div class="join-container flex flex-column md:ml-8 sm:flex-1 md:flex-none">
                <h2 class="md:mx-auto text-center">{{ $t("endpoints.pre-stream.header-1") }}</h2>
                <p class="text-center mx-5">{{ $t("endpoints.pre-stream.subheader") }}</p>
                <div class="join-button-container flex">
                    <Button :label="$t('endpoints.pre-stream.join-button')" class="join-button p-button-rounded p-button-raised p-button-lg mx-auto md:mt-1" icon="pi pi-arrow-right" icon-pos="right" @click="handleJoinNow"></Button>
                </div>
            </div>
        </div>
    </main>
</template>

<style scoped>
.video-container {
    position: relative;
    overflow: hidden;
    box-shadow: 0 0 20px 0 rgb(0, 0, 0, 0.3);
}

video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover; /* Maintain aspect ratio and cover the container */
    transform: scaleX(-1);
}

.join-button {
    padding: 0.7em;
}

@media screen and (max-width: 576px) {
    .video-container {
        width: auto;
        height: 100vw;
        margin-bottom: 2em;
    }
}

@media screen and (min-width: 577px) {
    .video-container {
        width: 48em;
        height: 27em;
    }
}
</style>
