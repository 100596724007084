// Hide all elements except the player
export function enterFalseFullScreen() {
    document.getElementById("app").firstElementChild.classList.add("layout-static-sidebar-inactive");
    document.querySelector("nav.layout-topbar").style.display = "none";
    document.querySelector("footer.layout-footer").style.display = "none";
    if (document.querySelector("div.chat-column")) {
        document.querySelector("div.chat-column").style.display = "none";
    }
    return true;
}

export function enterPreStreamPage() {
    document.getElementById("app").firstElementChild.classList.add("layout-static-sidebar-inactive");
    document.querySelector("footer.layout-footer").style.display = "none";
}

// Display some elements of the page
export function leaveFalseFullScreen() {
    document.querySelector("nav.layout-topbar").style.display = "flex";
    document.querySelector("nav.layout-topbar").style.position = "relative";
    if (document.querySelector("div.chat-column")) {
        document.querySelector("div.chat-column").style.display = "block";
    }
    return false;
}

// Toggle false fullscreen
export function toggleFalseFullScreen(isFalseFullScreen) {
    if (isFalseFullScreen) {
        return leaveFalseFullScreen();
    }
    return enterFalseFullScreen();
}

// Display all the elements of the page
export function leavePlayersPage() {
    document.getElementById("app").firstElementChild.classList.remove("layout-static-sidebar-inactive");
    document.querySelector("nav.layout-topbar").style.display = "flex";
    document.querySelector("nav.layout-topbar").style.position = "fixed";
    document.querySelector("footer.layout-footer").style.display = "flex";
    return false;
}
